<template>
  <PageWithLayout>
    <div class="inner_section">
      <h2 class="tit_help">고객센터</h2>
      <div class="area_overflow">
         <div class="area_left area_search">
          <Input
            placeholder="궁금한 것을 검색해 보세요"
            :value.sync="viewModel.model.searchKeyWord"
            @onInput="val => viewModel.onInput(val)">
            <template v-slot:tfFrontIcon>
              <div class="area_icon"><IconSvg iconName="search" :size="24" iconColor="#232323"/></div>
            </template>
          </Input>
        </div>
        <div class="area_overflow">
          <!-- 키워드 검색 -->
          <template v-if="viewModel.model.searchKeyWord">
            <button class="btn_back" @click="viewModel.onClickCloseSearch()"><IconSvg iconName="page_prev" :size="getIsMobile ? 8 : 12" iconColor="#232323"/>처음으로 돌아가기</button>
            <template v-if="viewModel.model.searchDataList.length > 0">
              <FoldList
                :dataList="viewModel.model.searchDataList"
                listStyle="Q"
                title="question"
                desc="answer"
                :isSize="true"
                :searchParams.sync="viewModel.model.searchParams"
                :paginationData="viewModel.model.paginationData"
                @onSearch="viewModel.onClickMore('SEARCH')"/>
            </template>
            <p v-else class="desc_empty">검색 결과가 없습니다.</p>
          </template>
          <!-- 카테고리 -->
          <template v-else>
            <!-- 카테고리 선택 -->
            <ul class="list_quick">
              <li
                :class="{active : viewModel.model.selectedCategoryId === 'TOP'}">
                <button class="btn_quick" @click="viewModel.onClickCategroy('TOP')">질문 TOP</button>
              </li>
              <li
                v-for="categoryData in viewModel.model.faqCategory"
                :key="`category${categoryData.id}`"
                :class="{active : viewModel.model.selectedCategoryId === categoryData.id}">
                <button class="btn_quick" @click="viewModel.onClickCategroy(categoryData.id)">{{ categoryData.name }}</button>
              </li>
            </ul>
            <!-- <Selectbox
              v-else
              :dataList="viewModel.model.faqCategorySelectDataList"
              :value.sync="viewModel.model.selectedCategoryId"
              @update:value="value => viewModel.onClickCategroy(value)" /> -->
            <!-- 카테고리별 목록 -->
            <template v-if="viewModel.model.faqDataList.length > 0">
              <FoldList
                :dataList="viewModel.model.faqDataList"
                :orderNum="viewModel.model.selectedCategoryId === 'TOP' ? 'position' : ''"
                listStyle="Q"
                title="question"
                desc="answer"
                :isSize="viewModel.model.selectedCategoryId != 'TOP'"
                :searchParams.sync="viewModel.model.searchParams"
                :paginationData="viewModel.model.paginationData"
                @onSearch="viewModel.onClickMore('CATEGORY')"/>
            </template>
            <p v-else class="desc_empty">{{ viewModel.model.selectedCategoryId | convertIdToText(viewModel.model.faqCategorySelectDataList) }}의 FAQ가 없습니다.</p>
          </template>
          <!-- 1:1 문의 버튼 -->
          <button
            id="channelTalkBtn"
            ref="channelTalkBtn"
            :class="{ sticky : isSticky }">
            <span class="txt_label">1:1 문의</span>
            <span class="img_channel_talk"></span>
          </button>
        </div>
      </div>
    </div>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import Input from '@/components/common/input/Input';
import IconSvg from '@/components/common/icon/IconSvg';
import Selectbox from '@/components/common/selectbox/Selectbox';
import FoldList from '@/components/common/fold/FoldList'
import { mapGetters } from 'vuex';
// mixin
import ChannelServiceMixin from '@/mixins/ChannelServiceMixin'
// viewModel
import HelpVeiwModel from '@/views/help/viewModel/HelpVeiwModel'

export default {
  name: 'Help',
  mixins:[ChannelServiceMixin],
  components:{
    PageWithLayout,
    Input,
    IconSvg,
    Selectbox,
    FoldList
  },
  beforeMount(){
    this.viewModel.init();
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getIsMobile' ]),
  },
  data(){
    return{
      viewModel: new HelpVeiwModel(),
    }
  },
  watch:{
    'viewModel.model.faqDataList':{
      deep: true,
      handler(newVal, oldVal){
        if(newVal != oldVal){
          this.stopSticky();
        }
      }
    },
    'viewModel.model.searchKeyWord':{
      deep: true,
      handler(newVal, oldVal){
        if(newVal || !newVal){
          this.stopSticky();
        }
      }
    },
  },
}
</script>

<style scoped>
.tit_help{padding-top:160px;font-weight:700;font-size:48px;line-height:64px;color:#111}
.tit_help + .area_overflow{margin-top:80px}

.area_search{margin-right:80px}
.area_search .tf_comm{width:354px}
.area_search .tf_comm >>> .area_icon{display:block;padding:16px 0 0 16px}
.area_search .tf_comm >>> .area_icon + input{padding-left:12px}

/* .tit_section{overflow:hidden;position:absolute;width:0;height:0;line-height:0;text-indent:-9999px} */

.btn_back{padding:14px 16px 14px 16px;border-radius:4px;border:1px solid #A6A4A4;font-weight:700;font-size:18px;line-height:28px;color:#232323}
.btn_back .icon_page_prev{margin:8px 14px 8px 6px}
.btn_back + .fold_comm{margin-top:16px}

.inner_section{position:relative;padding-bottom:176px}
.fold_comm{padding-bottom:100px}
.fold_pagination{padding-bottom:0}

.list_quick{overflow:hidden;margin:-8px}
.list_quick li{display:inline-block;padding:8px;vertical-align:top}
.list_quick li .btn_quick{display:block;padding:14px 16px;border-radius:4px;font-weight:700;font-size:24px;line-height:30px;color:#999;letter-spacing:-0.02em}
.list_quick li.active .btn_quick{background-color:#F0EAFA;color:#8F69DD}
.list_quick + .fold_comm{margin-top:32px}

.desc_empty{margin-top:32px;font-weight:500;font-size:18px;line-height:36px;color:#999}

/* 모바일 */
@media all and (max-width:1199px){
  .tit_help{padding-top:94px;font-weight:700;font-size:28px;line-height:42px}
  .tit_help + .area_overflow{margin-top:24px}

  .area_search{float:none;margin:0}
  .area_search .tf_comm{width:100%;max-width:354px}
  /* .tit_section{overflow:inherit;position:static;width:inherit;height:inherit;line-height:inherit;text-indent:inherit;padding:16px 0;font-weight:600;font-size:14px;line-height:22px;color:#666} */
  
  .inner_section{padding-bottom:160px}

  .list_quick{padding-top:24px;margin:-5px -4px}
  .list_quick li{padding:5px 4px}
  .list_quick li .btn_quick{display:block;padding:6px 10px;font-weight:800;font-size:16px;line-height:24px}

  /* .selectbox_comm{margin-top:24px} */
  .btn_back{margin-top:24px;padding:12px 16px 12px 16px;font-size:16px;line-height:24px}
  .btn_back .icon_page_prev{margin:8px 6px 8px 2px}

  .fold_comm{padding-bottom:0}
  .list_quick + .fold_comm{margin-top:24px}
}
</style>