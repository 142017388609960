import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@/utils/urlUtils';
import apiPath from '@/services/apiPath';
import HelpModel from '@/views/help/model/HelpModel';


export default class HelpVeiwModel {
  constructor() {
    this.model = new HelpModel()
  }
  init(){
    this.getCategoryData();
    this.getTopTenData();
  }

  // 카테고리 선택
  onClickCategroy(id){
    this.model.selectedCategoryId = id;
    this.onResetSearch();
    if(id != 'TOP'){
      const categoryHisgoryItem = this.model.getIsCategoryHistoryData(id);
      // 이미 선택한 값일 경우
      if(categoryHisgoryItem && this.model.searchParams.page === 0){
        this.model.getCategoryHistoryData(categoryHisgoryItem);      
      // 새로 선택한 값일 경우
      }else{
        this.getCategoryListData(id)
      }
    }else{
      this.model.getTopTenData();
    }
  }

  // 목록 더보기
  onClickMore(type){
    this.model.searchParams.page += 1;
    switch(type){
      case 'SEARCH' : {
        this.getSearch(this.model.searchKeyWord);
        break;
      }
      case 'CATEGORY' : {
        this.getCategoryListData(this.model.selectedCategoryId);
        break;
      }
    }
  }

  // 실시간 키워드 검색
  onInput(e){
    let keyWord = '';
    if(e && e.inputType === 'insertCompositionText'){
      keyWord = this.model.searchKeyWord+e.data;
    }else{
      keyWord = this.model.searchKeyWord;
    }
    this.checkSearchHistory(keyWord)
  }

  // 검색 히스토리 확인
  checkSearchHistory(keyWord){
    if(keyWord != ''){
      keyWord = keyWord.trim();
      // 이미 검색한 값일 경우
      const searchHistoryItem = this.model.getIsSearchHistoryDataList(keyWord);
      if(searchHistoryItem && this.model.searchParams.page === 0){
        this.model.getSearchHistoryDataList(searchHistoryItem);      
      // 새로 검색한 값일 경우
      }else{
        this.model.resetSearcWithoutKeyword();
        this.getSearch(keyWord);
      }
    }else{
      this.onClickCloseSearch();
    }
  }

  // 검색 종료
  onClickCloseSearch(){
    this.onResetSearch();
    this.onClickCategroy(this.model.selectedCategoryId)
  }

  // 검색 리셋
  onResetSearch(){
    window.scrollTo({top:0})
    this.model.resetSearch();
  }

  // API] 카테고리
  getCategoryData(){
    const path = `${apiPath.FAQ_CATEGORY}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setCategoryData(resultData)
    })
  }
  // API] top 10 목록
  getTopTenData(){
    const path = `${apiPath.FAQ_TOP_LIST}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setTopTenData(resultData)
    })
  }
  // API] 카테고리 목록 가져오기
  getCategoryListData(id){
    if(this.model.searchParams.page === 0){
      this.model.faqDataList = []
    }
    const path = `${apiPath.FAQ_CATEGORY_LIST.format(id)}${makeQueryStringByObject(this.model.searchParams)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      if(this.model.searchParams.page === 0){
        this.model.setCategoryHistoryData(id,resultData)
      }else{
        this.model.setFaqDataListConcat(resultData.data);
      }
      this.model.setPaginationData(resultData)
    })
  }
  // API] 실시간 키워드 검색
  getSearch(searchKeyWord){
    if(this.model.searchParams.page === 0){
      this.model.searchDataList = []
    }
    const path = `${apiPath.FAQ_SEARCH.format(searchKeyWord)}${makeQueryStringByObject(this.model.searchParams)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      if(this.model.searchParams.page === 0){
        this.model.setSearchHistoryDataList(searchKeyWord,resultData)
      }else{
        this.model.setSearchDataListConcat(resultData.data);
      }
      this.model.setPaginationData(resultData)
    })
  }
}