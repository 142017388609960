<template>
  <div class="tf_comm">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InputBase',
}
</script>

<style scoped>
.tf_comm{display:inline-block;position:relative;width:100%;padding-bottom:0;vertical-align:top;box-sizing:border-box}
.tf_comm .inner_input{overflow:hidden;height:56px;border-radius:4px;background:none;box-sizing:border-box;background-color:#F3F1F1;caret-color:#8F69DD;transition:background-color 0s}
.tf_comm input{display:block;flex-grow:1;width:100%;height:56px;padding:0 16px;outline:none;border:0 none;font-weight:bold;font-size:16px;background:none;color:#111;}

/* count */
.tf_comm.disabled .desc_count{opacity:.5}
.desc_count{padding:6px 0 0;font-weight:bold;font-size:16px;line-height:26px;color:#888;text-align:right}
.desc_count em{font-weight:bold;font-size:16px;line-height:26px}
.desc_count em.on{font-weight:800;color:#333}

/* large */
.tf_comm.tf_large .inner_input{height:56px;border-radius:18px}
.tf_comm.tf_large input{height:52px;padding:0 20px}
.tf_comm.tf_large button{padding:18px 10px}
.tf_comm.tf_large .area_util button:last-child{padding-right:20px}

/* autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{transition: background-color 5000s;-webkit-text-fill-color: #333 !important}

/* placeholder */
.tf_comm input::-webkit-input-placeholder{font-weight:normal;color:#999}
.tf_comm input:-moz-placeholder{font-weight:normal;color:#999}
.tf_comm input::-moz-placeholder{font-weight:normal;color:#999}
.tf_comm input:-ms-input-placeholder{font-weight:normal;color:#999}
/* focus & valued */
.tf_comm:focus-within .inner_input{background-color:#F0EAFA}
/* .tf_comm:focus-within .inner_input >>> .area_icon .icon_lemontree g,
.tf_comm.tf_valued .inner_input >>> .area_icon .icon_lemontree g{fill:#3DBF69} */

/* focus */
.tf_comm.tf_focus .inner_input{background-color:#F0EAFA}

/* error */
.tf_comm.tf_error{padding-bottom:0}
.tf_comm.tf_error .inner_input{background-color:#FDEFEF;color:#E23C3C}
/* error placeholder */
.tf_comm.tf_error input::-webkit-input-placeholder{color:#E23C3C}
.tf_comm.tf_error input:-moz-placeholder{color:#E23C3C}
.tf_comm.tf_error input::-moz-placeholder{color:#E23C3C}
.tf_comm.tf_error input:-ms-input-placeholder{color:#E23C3C}
/* error focus */
.tf_comm.tf_error:focus-within .inner_input{color:#fff}
/* error focus placeholder */
.tf_comm.tf_error:focus-within input::-webkit-input-placeholder{font-weight:normal;color:#999}
.tf_comm.tf_error:focus-within input:-moz-placeholder{font-weight:normal;color:#999}
.tf_comm.tf_error:focus-within input::-moz-placeholder{font-weight:normal;color:#999}
.tf_comm.tf_error:focus-within input:-ms-input-placeholder{font-weight:normal;color:#999}
/* error desc */
.desc_error{padding:0 0 6px;font-weight:bold;font-size:14px;line-height:24px;color:#E23C3C}
.desc_error .icon_emoji{display:inline-block;margin-right:8px;vertical-align:top}

/* disabled */
.tf_comm.disabled:focus-within input,
.tf_comm.disabled .inner_input{border-color:#f0f0f0;background-color:#fbfbfb}
.tf_comm.disabled input:disabled{color:#aaa}

/* util */
.tf_has_util .inner_input{display:flex;flex-wrap:nowrap}
.tf_has_util input{flex-grow:1;}
.area_util{display:none;align-items:flex-end;line-height:18px}
.area_util button{padding:16px 14px 16px;line-height:18px}
.area_util button:last-child{padding-right:14px}
.area_util button:hover .icon_lemontree >>> g{fill:#777}
.tf_has_util:focus-within .area_util{display:block}
/* .tf_has_util:focus-within .area_util .icon_lemontree >>> g,
.tf_has_util.tf_valued .area_util .icon_lemontree >>> g{fill:#3DBF69} */

</style>