<template>
  <div :class="['fold_item', isFold ? 'folded':'opened'  ]">
    <div class="head_fold" @click="onClickFold()">
      <span
        v-if="( orderNum && itemData[orderNum] ) || listStyle"
        class="txt_num">{{ orderNum && itemData[orderNum] ? itemData[orderNum] : listStyle }}</span>
      <span
        v-if="itemData[iconTitle]"
        class="icon_title"
        :style="{
          width: `${itemData[iconTitle].size}px`,
          height: `${itemData[iconTitle].size}px`,
          background: `url(${itemData[iconTitle].url}) no-repeat 0 0`,
          backgroundSize: `${itemData[iconTitle].size}px ${itemData[iconTitle].size}px`,
          marginRight: `${itemData[iconTitle].marginRight}px`,
        }"></span>
      <strong class="tit_fold" v-html="itemData[title]"></strong>
      <IconSvg
        iconName="arrow_fold"
        :size="getIsMobile ? 24 : 32"
        iconColor="#A6A4A4"/>
    </div>
    <div class="body_fold">
      <div v-if="desc" class="inner_body_fold" v-html="itemData[desc]"></div>
      <template v-else>
        <div class="inner_body_fold">
          <slot/>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import IconSvg from '@/components/common/icon/IconSvg';
import { mapGetters } from 'vuex';

export default {
  name: 'FoldItem',
  props:{
    itemData: Object,
    index: Number,
    orderNum: String,
    listStyle: String,
    iconTitle: String,
    title: String,
    desc: String,
    isFoldItemIndex:[Number,String]
  },
  components:{
    IconSvg
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getIsMobile' ]),
    isFold(){
      return this.isFoldItemIndex != this.index;
    }
  },
  methods:{
    onClickFold(){
      let value = null
      if(this.isFoldItemIndex != this.index){
        value = this.index;
      }
      this.$emit('update:isFoldItemIndex',value)
    }
  }
}
</script>

<style scoped>
.fold_item{word-wrap:break-word;word-break:keep-all}

.head_fold{overflow:hidden;position:relative;padding:25px 32px 25px 0;cursor:pointer}
.head_fold .txt_num{float:left;padding-right:16px;font-weight:700;font-size:24px;line-height:30px;color:#8F69DD}
.head_fold .icon_title{float:left}
.head_fold .tit_fold{display:block;overflow:hidden;font-weight:700;font-size:24px;line-height:30px;color:#111}
.head_fold .icon_arrow_fold{position:absolute;top:24px;right:0px}
.opened .head_fold .icon_arrow_fold{transform:scaleY(-1)}

.body_fold{overflow:hidden;transition:max-height 0.2s ease-in}
.body_fold .inner_body_fold{padding:8px 0 32px;font-weight:500;font-size:18px;line-height:36px;color:#666}
.folded .body_fold{max-height:0}
.opened .body_fold{max-height:800px}

.body_fold .inner_body_fold >>> a{color:#579BF5;text-decoration:underline}
.body_fold .inner_body_fold >>> ul,
.body_fold .inner_body_fold >>> ul > li{list-style:disc}
.body_fold .inner_body_fold >>> ul{padding-left:20px;text-indent:-4px}
.body_fold .inner_body_fold >>> ul > li > ul,
.body_fold .inner_body_fold >>> ul > li > ul > li{list-style:circle}
.body_fold .inner_body_fold >>> ul > li > ul > li > ul,
.body_fold .inner_body_fold >>> ul > li > ul > li > ul > li{list-style:square}
.body_fold .inner_body_fold >>> ol,
.body_fold .inner_body_fold >>> ol > li{list-style:decimal}
.body_fold .inner_body_fold >>> ol{padding-left:26px}
.body_fold .inner_body_fold >>> ol > li > ol,
.body_fold .inner_body_fold >>> ol > li > ol > li{list-style:lower-latin}
.body_fold .inner_body_fold >>> ol > li > ol > li > ol,
.body_fold .inner_body_fold >>> ol > li > ol > li > ol > li{list-style:lower-roman}
.body_fold .inner_body_fold >>> ol > li > ol{padding-left:20px}



/* 모바일 */
@media all and (max-width:1199px){
  .head_fold{padding:16px 32px 16px 0}
  .head_fold .txt_num{padding-right:8px;font-size:16px;line-height:24px}
  .head_fold .tit_fold{font-size:16px;line-height:24px}
  .head_fold .icon_arrow_fold{top:16px;right:0}

  .body_fold .inner_body_fold{padding:0 0 16px;font-size:14px;line-height:22px}
}
/* 
.fold_item{word-wrap:break-word;word-break:keep-all}

.head_fold{overflow:hidden;position:relative;padding:16px 52px 16px 20px}
.head_fold .txt_num{float:left;padding-right:8px;font-weight:700;font-size:16px;line-height:24px;color:#8F69DD}
.head_fold .icon_title{float:left}
.head_fold .tit_fold{display:block;overflow:hidden;font-weight:700;font-size:16px;line-height:24px;color:#111}
.head_fold .icon_arrow_fold{position:absolute;top:16px;right:20px}
.opened .head_fold .icon_arrow_fold{transform:scaleY(-1)}

.body_fold{overflow:hidden;transition:max-height 0.2s ease-in}
.body_fold .inner_body_fold{padding:0 20px 16px;font-weight:500;font-size:14px;line-height:22px;color:#666}
.folded .body_fold{max-height:0}
.opened .body_fold{max-height:800px}

.body_fold .inner_body_fold >>> a{color:#579BF5;text-decoration:underline}
.body_fold .inner_body_fold >>> ul,
.body_fold .inner_body_fold >>> ul > li{list-style:disc}
.body_fold .inner_body_fold >>> ul{padding-left:20px;text-indent:-4px}
.body_fold .inner_body_fold >>> ul > li > ul,
.body_fold .inner_body_fold >>> ul > li > ul > li{list-style:circle}
.body_fold .inner_body_fold >>> ul > li > ul > li > ul,
.body_fold .inner_body_fold >>> ul > li > ul > li > ul > li{list-style:square}
.body_fold .inner_body_fold >>> ol,
.body_fold .inner_body_fold >>> ol > li{list-style:decimal}
.body_fold .inner_body_fold >>> ol{padding-left:26px}
.body_fold .inner_body_fold >>> ol > li > ol,
.body_fold .inner_body_fold >>> ol > li > ol > li{list-style:lower-latin}
.body_fold .inner_body_fold >>> ol > li > ol > li > ol,
.body_fold .inner_body_fold >>> ol > li > ol > li > ol > li{list-style:lower-roman}
.body_fold .inner_body_fold >>> ol > li > ol{padding-left:20px} */
</style>